import { post } from "@src/appV2/api/api";
import { environmentConfig } from "@src/appV2/environment";
import { APP_V2_APP_EVENTS } from "@src/appV2/lib";
import {
  type CreateShiftFeedbackRequest,
  CreateShiftFeedbackResponseSchema,
  type ShiftFeedbackResponse,
} from "@src/appV2/ShiftFeedback/schema";
import {
  useMutation,
  type UseMutationOptions,
  type UseMutationResult,
} from "@tanstack/react-query";
import { type AxiosError } from "axios";

export function useCreateShiftSigningOutFeedback(
  options: UseMutationOptions<ShiftFeedbackResponse, AxiosError, CreateShiftFeedbackRequest> = {}
): UseMutationResult<ShiftFeedbackResponse, AxiosError, CreateShiftFeedbackRequest> {
  return useMutation({
    mutationFn: async (data) => {
      const { workerId, ...shiftFeedback } = data;
      const apiResponse = await post({
        url: `${environmentConfig.REACT_APP_BASE_API_URL}/workers/${workerId}/shift-feedbacks`,
        data: { data: { attributes: shiftFeedback } },
        responseSchema: CreateShiftFeedbackResponseSchema,
      });

      return apiResponse.data.data;
    },
    meta: {
      logErrorMessage: APP_V2_APP_EVENTS.CREATE_SHIFT_SIGNING_OUT_FEEDBACK_FAILURE,
    },
    ...options,
  });
}
